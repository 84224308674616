
import { defineComponent } from 'vue'

// components

export default defineComponent({
    name: 'p_components_layout_basic_element',

    data () {
        return {
            show_headline_link_symbol: false
        }
    },

    props: {
        headline: {
            default  : '',
            required : false
        },
        link: {
            default  : '#',
            required : false
        },
        custom_padding: {
            default  : false,
            required : false
        }
    },

    methods: {
        p_click_on_headline (value: string) {
            if (value !== null && value !== '') {
                this.$router.push(`#${value}`)
                this.show_headline_link_symbol = true

                const domain = 'https://pagisto.com/!' || 'pagisto.com/!'
                const full_url = `${domain}${this.$route.fullPath}`

                navigator.clipboard.writeText(full_url)
            }
        }
    },

    watch: {
        '$route.hash': {
            handler (new_value: string) {
                if (new_value !== `#${this.link}`) {
                    this.show_headline_link_symbol = false
                }
            },
            immediate: true
        }
    },

    mounted () {
        if (this.$route.hash === `#${this.link}`) {
            this.show_headline_link_symbol = true
        }
    }
})
