import { GetterTree } from 'vuex'
import { STATE_Root } from '@/store/index'
import { STATE_Helper } from '@/types/states/helper'

const getters: GetterTree<STATE_Helper, STATE_Root> = {
    G_CHART_COLOR (state) {
        const d = state.dark
        if (d.is_dark) {
            return d.chart_dark_colors
        } else {
            return d.chart_light_colors
        }
    }
}

export default getters
