import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-912b5ea0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row justify-center q-mt-md",
  style: {"width":"100%"}
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "row justify-center q-mt-md",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_cta = _resolveComponent("p-cta")!
  const _component_p_products_element = _resolveComponent("p-products-element")!

  return (['products'].includes(_ctx.type))
    ? (_openBlock(), _createBlock(_component_p_products_element, {
        key: 0,
        headline: _ctx.data.title,
        link: _ctx.$utf8.encode(_ctx.data.title !== null ? _ctx.data.title : ''),
        custom_padding: true
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.person || [], (element) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(element.__typename)), {
              key: element,
              class: "q-ma-md",
              data: element.data
            }, null, 8, ["data"]))
          }), 128)),
          ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_p_cta, {
                  type: _ctx.type,
                  data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
                }, null, 8, ["type", "data"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["p-name-banner q-px-md q-py-sm bg-primary text-subtitle1 text-weight-medium text-white", {
                    'p-name-banner-small': _ctx.$q.screen.lt.sm
                }])
          }, _toDisplayString(_ctx.$t('app.pages.editor.system_partner', { x: _ctx.$route.meta.page_ref_content.label || ''})), 3)
        ]),
        _: 1
      }, 8, ["headline", "link"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.person || [], (element) => {
                return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(element.__typename)), {
                  key: element,
                  class: "q-ma-md",
                  data: element.data
                }, null, 8, ["data"]))
              }), 128)),
              _createVNode(_component_p_cta, {
                type: _ctx.type,
                data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
              }, null, 8, ["type", "data"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["p-name-banner q-px-md q-py-sm bg-primary text-subtitle1 text-weight-medium text-white", {
                    'p-name-banner-small': _ctx.$q.screen.lt.sm
                }])
        }, _toDisplayString(_ctx.$t('app.pages.editor.system_partner', { x: _ctx.$route.meta.page_ref_content.label || ''})), 3)
      ]))
}