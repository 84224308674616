
import { CMS_COMPONENT_Payment } from '@/types/cms/payments'
import { defineComponent } from 'vue'

// components
import Tooltip from '@/components/Tooltip.vue'

export default defineComponent({
    name: 'p_components_pages_payment',

    components: {
        'p-tooltip': Tooltip
    },

    props: {
        data: {
            default: new CMS_COMPONENT_Payment()
        }
    },

    data () {
        return {
            is_hover: false
        }
    }
})
