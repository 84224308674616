
import { API_Query_Asset } from '@/types/API'
import { defineComponent } from 'vue'

// components

export default defineComponent({
    name: 'p_components_main_products_product',

    props: {
        title: {
            default  : '',
            required : false
        },
        description: {
            default  : '',
            required : false
        },
        link: {
            default  : '#',
            required : false
        },
        image: {
            default: new API_Query_Asset()
        },
        comming_soon: {
            default  : false,
            required : false
        }
    }
})
