
export class CMS_SCHEMA_Reviews {
    name?: { [key: string]: string }
    message?: { [key: string]: string }
    date?: { [key: string]: Date }
    rating?: { [key: string]: number }
    __typename?: string

    constructor () {
        this.name = { de: '' }
        this.message = { de: '' }
        this.rating = { iv: 0 }
        this.date = { iv: new Date() }
        this.__typename = ''
    }
}
