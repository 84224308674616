import { API_Query_Asset, API_Query_Data_Helper } from '../API'
import { CMS_SCHEMA_Customers } from './customers'

export class CMS_SCHEMA_Editor {
    name?: { [key: string]: string }
    description?: { [key: string]: string }
    position?: { [key: string]: string }
    image?: API_Query_Asset[]
    customer?: { [key: string]: Array<API_Query_Data_Helper<CMS_SCHEMA_Customers>> }
    __typename?: string

    constructor () {
        this.name = { de: '' }
        this.description = { de: '' }
        this.position = { de: '' }
        this.image = []
        this.customer = { data: [] }
        this.__typename = ''
    }
}
