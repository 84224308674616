
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'p_components_main_hero_menu',

    props: {
        data: {
            default: ['']
        }
    }
})
