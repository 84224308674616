
import { CMS_SCHEMA_Person } from '@/types/cms/person'
import { defineComponent } from 'vue'

// components

export default defineComponent({
    name: 'p_components_main_team_person',

    props: {
        data: {
            default: new CMS_SCHEMA_Person()
        }
    },

    data () {
        return {
            ratingModel: 4
        }
    }
})
