import { Module } from 'vuex'
import { STATE_Root } from '@/store/index'
import state from './state'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { STATE_Helper } from '@/types/states/helper'

const exampleModule: Module<STATE_Helper, STATE_Root> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}

export default exampleModule
