import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "row justify-start no-wrap" }
const _hoisted_2 = ["alt", "title", "src", "height", "width"]
const _hoisted_3 = { class: "text-h6 q-pr-md" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_ripple = _resolveDirective("ripple")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives((_openBlock(), _createBlock(_component_q_card, {
      onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.on_hover(true))),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.on_hover(false))),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.p_open(true))),
      flat: "",
      class: _normalizeClass(["bg-accent2", {
                'bg-accent': _ctx.is_hover && !_ctx.$q.dark.isActive,
                'bg-accent3': _ctx.is_hover && _ctx.$q.dark.isActive
            }]),
      style: _normalizeStyle([{"transition":"background .3s"}, `max-width: ${ _ctx.$q.screen.lt.md ? 200 : 300 }px; overflow: hidden; cursor: pointer`])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, {
          class: "row justify-center",
          style: {"overflow":"hidden"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.image_parser(_ctx.data.logo), _ctx.image_parser(_ctx.data.logoLight), _ctx.image_parser(_ctx.data.logoDark)], (img, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: img }, [
                  ((index === 1 && !_ctx.$q.dark.isActive) || (index === 2 && _ctx.$q.dark.isActive) || index === 0)
                    ? _withDirectives((_openBlock(), _createElementBlock("img", {
                        key: 0,
                        loading: "lazy",
                        alt: img.alt,
                        title: img.alt,
                        src: img.url,
                        height: _ctx.$q.screen.lt.md ? 60 : 90,
                        width: _ctx.$q.screen.lt.md ? 100 : 150
                      }, null, 8, _hoisted_2)), [
                        [_vShow, index !== 0 ? (!_ctx.is_hover && !_ctx.is_blink) : (_ctx.is_hover || _ctx.is_blink)]
                      ])
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class", "style"])), [
      [_directive_ripple, { color: 'primary' }]
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.open,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.open) = $event)),
      maximized: _ctx.$q.screen.lt.md,
      onBeforeHide: _cache[4] || (_cache[4] = ($event: any) => (_ctx.p_dialog_close()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, {
          class: "bg-accent2",
          style: {"position":"relative","max-height":"800px"}
        }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_q_btn, {
              style: {"position":"absolute","top":"10px","right":"10px","z-index":"4"},
              icon: "close",
              flat: "",
              round: "",
              dense: ""
            }, null, 512), [
              [_directive_close_popup]
            ]),
            _createVNode(_component_q_card_section, { class: "q-mx-md q-mt-md row justify-between no-wrap items-start" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(Object.values(_ctx.data.label || { de: '' })[1]), 1),
                _createElementVNode("img", {
                  class: "q-mr-md",
                  src: _ctx.image_parser(_ctx.data.logo).url,
                  width: "100"
                }, null, 8, _hoisted_4)
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_section, {
              class: "q-mx-md",
              style: {"max-height":"550px","overflow":"hidden auto"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: Object.values(_ctx.data.description || { de: '' })[1]
                }, null, 8, _hoisted_5)
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_actions, {
              align: "around",
              class: "q-mb-md"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  "no-caps": "",
                  type: "a",
                  href: Object.values(_ctx.data.link || { de: '' })[1],
                  target: "__blank",
                  "aria-label": _ctx.$t('app.learn_more_about', { x: Object.values(_ctx.data.label || { de: '' })[1] }),
                  label: _ctx.$t('app.learn_more_about', { x: Object.values(_ctx.data.label || { de: '' })[1] }),
                  color: "primary",
                  "icon-right": "chevron_right"
                }, null, 8, ["href", "aria-label", "label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "maximized"])
  ]))
}