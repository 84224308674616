import { boot } from 'quasar/wrappers'
import utf8 from 'utf8'


declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $utf8: any
    }
}

export default boot(({ app }) => {
    app.config.globalProperties.$utf8 = utf8
})

export { utf8 }
