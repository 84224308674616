import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "row justify-evenly wrap",
  style: {"width":"calc(100% + 32px)","margin-left":"-16px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_basic_element = _resolveComponent("p-basic-element")!

  return (_openBlock(), _createBlock(_component_p_basic_element, {
    headline: _ctx.data.title,
    link: _ctx.data.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.products || [], (element) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(element.__typename)), {
            key: element,
            class: "q-ma-md",
            comming_soon: _ctx.parser({ label: 'comming_soon', element }),
            title: _ctx.parser({ label: 'title', element }),
            link: _ctx.parser({ label: 'link', element }),
            video_link: _ctx.parser({ label: 'video', element }),
            image: _ctx.parser({ label: 'image', element }),
            description: _ctx.parser({ label: 'description', element })
          }, null, 8, ["comming_soon", "title", "link", "video_link", "image", "description"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["headline", "link"]))
}