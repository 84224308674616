import { API_Query_Data_Helper } from '@/types/API'
import { CMS_SCHEMA_Pages } from '../pages'

export class CMS_COMPONENT_Routerinfo {
    routeName?: string
    childOfPage?: API_Query_Data_Helper<CMS_SCHEMA_Pages>[]
    slug?: string
    redirectPath?: string

    constructor () {
        this.routeName = ''
        this.childOfPage = []
        this.slug = ''
    }
}
