import { API_Query_Asset } from '../API'

export class CMS_SCHEMA_Customers {
    name?: { [key: string]: string }
    label?: { [key: string]: string }
    link?: { [key: string]: string }
    logoLight?: { [key: string]: API_Query_Asset[] }
    logoDark?: { [key: string]: API_Query_Asset[] }
    logo?: { [key: string]: API_Query_Asset[] }
    description?: { [key: string]: string }
    __typename?: string

    constructor () {
        this.name = { iv: '' }
        this.label = { de: '' }
        this.link = { de: '' }
        this.logoLight = { iv: [] }
        this.logoDark = { iv: [] }
        this.logo = { iv: [] }
        this.description = { de: '' }
        this.__typename = ''
    }
}
