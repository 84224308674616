
import { defineComponent } from 'vue'

// components
import Basic_Element from '@/components/layout/Basic_Element.vue'
import { CMS_COMPONENT_Homepageteam } from '@/types/cms/homepage'


export default defineComponent({
    name: 'p_components_main_team',

    components: {
        'p-basic-element': Basic_Element
    },

    props: {
        data: {
            default : new CMS_COMPONENT_Homepageteam(),
            type    : CMS_COMPONENT_Homepageteam
        }
    }
})
