
import { defineComponent } from 'vue'

// components
import Basic_Element from '@/components/layout/Basic_Element.vue'
import { CMS_COMPONENT_Homepageproducts } from '@/types/cms/homepage'
import { CMS_SCHEMA_Products } from '@/types/cms/products'
import { API_Query_Data_Helper } from '@/types/API'


export default defineComponent({
    name: 'p_components_main_products',

    components: {
        'p-basic-element': Basic_Element
    },

    props: {
        data: {
            default : new CMS_COMPONENT_Homepageproducts(),
            type    : CMS_COMPONENT_Homepageproducts
        }
    },

    methods: {
        parser ({ label, element }: { label: string, element: API_Query_Data_Helper<CMS_SCHEMA_Products> }) {
            const base = Object.values(element.data.page || { })[1][0].data
            const ref_c = Object.values(base.refContent || { })[1]

            switch (label){
            case 'comming_soon':
                return Object.values(element.data.commingSoon || { })[1]
            case 'title':
                return ref_c.label
            case 'image':
                if (ref_c.image !== null) return ref_c.image[0]
                return ''
            case 'description':
                return ref_c.descriptionShort
            case 'link':
                return Object.values(base.routing || {})[1].routeName
            default:
                return ''
            }
        }
    }
})
