import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-670c4749"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt", "title", "src"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "q-mt-md q-mr-md" }
const _hoisted_4 = ["alt", "title", "src"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["full-width bg-accent justify-between items-start p-card q-px-lg q-py-xl", {
            'row': _ctx.$q.screen.gt.sm,
            'column': _ctx.$q.screen.lt.md
        }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({
                'q-pa-lg': _ctx.$q.screen.gt.sm,
                'q-py-md q-px-xs': _ctx.$q.screen.lt.md
            }),
      style: {"flex":"1"}
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["no-wrap", {
                    'row justify-start items-start': _ctx.$q.screen.gt.sm,
                    'column justify-start items-center': _ctx.$q.screen.lt.md
                }])
      }, [
        _createElementVNode("img", {
          style: {"border-radius":"5px"},
          loading: "lazy",
          height: "250",
          width: "250",
          alt: (Object.values(_ctx.data.image || { de: [{ alt: '' }] })[1] || [{ alt: '' }])[0].alt || '',
          title: (Object.values(_ctx.data.image || { de: [{ alt: '' }] })[1] || [{ alt: '' }])[0].alt || '',
          src: `
                        ${ (Object.values(_ctx.data.image || { de: [{ url: '' }] })[1] || [{ url: '' }])[0].url }${
                            (Object.values(_ctx.data.image || { de: [{ imageQuality: '' }] })[1] || [{ imageQuality: 0 }])[0].imageQuality ?
                            '?quality=' + (Object.values(_ctx.data.image || { de: [{ imageQuality: '' }] })[1] || [{ imageQuality: 0 }])[0].imageQuality :
                            ''
                        }
                    `
        }, null, 8, _hoisted_1),
        _createElementVNode("div", {
          class: _normalizeClass({
                        'q-ml-md q-mt-lg': _ctx.$q.screen.gt.sm,
                        'q-mt-md': _ctx.$q.screen.lt.md
                    })
        }, [
          _createElementVNode("div", {
            style: {"word-break":"break-word"},
            class: _normalizeClass({
                            'text-h2': _ctx.$q.screen.gt.sm,
                            'text-h5 text-center': _ctx.$q.screen.lt.md
                        })
          }, _toDisplayString(Object.values(_ctx.data.name || { de: '' })[1]), 3),
          _createElementVNode("div", {
            class: _normalizeClass(["text-subtitle1 text-weight-medium", {
                            'q-mt-md': _ctx.$q.screen.gt.sm,
                            'q-mt-xs text-center': _ctx.$q.screen.lt.md
                        }]),
            style: {"word-break":"break-word"}
          }, _toDisplayString(Object.values(_ctx.data.position || { de: '' })[1]), 3)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: "q-mt-xl",
        innerHTML: Object.values(_ctx.data.description || { de: '' })[1]
      }, null, 8, _hoisted_2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass({
                'q-pa-lg': _ctx.$q.screen.gt.sm,
                'q-py-md q-px-xs': _ctx.$q.screen.lt.md
            }),
      style: {"flex":"1"}
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["no-wrap", {
                    'row justify-start items-start': _ctx.$q.screen.gt.sm,
                    'column justify-start items-center': _ctx.$q.screen.lt.md
                }])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass({
                            'text-h2': _ctx.$q.screen.gt.sm,
                            'text-h5 text-center q-mb-sm': _ctx.$q.screen.lt.md
                        }),
            style: {"word-break":"break-word"}
          }, _toDisplayString(Object.values(Object.values(_ctx.data.customer || { de: [] })[1][0].data.label || { de: '' })[1]), 3)
        ]),
        _createElementVNode("img", {
          style: {"border-radius":"5px"},
          loading: "lazy",
          height: "120",
          width: "200",
          alt: (Object.values(Object.values(_ctx.data.customer || { de: [] })[1][0].data.logo || { alt: '' })[1] || [{ alt: '' }])[0].alt || '',
          title: (Object.values(Object.values(_ctx.data.customer || { de: [] })[1][0].data.logo || { alt: '' })[1] || [{ alt: '' }])[0].alt || '',
          src: `
                        ${ (Object.values(Object.values(_ctx.data.customer || { de: [] })[1][0].data.logo || { url: '' })[1] || [{ url: '' }])[0].url }${
                            (Object.values(Object.values(_ctx.data.customer || { de: [] })[1][0].data.logo || { imageQuality: '' })[1] || [{ imageQuality: 0 }])[0].imageQuality ?
                            '?quality=' + (Object.values(Object.values(_ctx.data.customer || { de: [] })[1][0].data.logo || { imageQuality: '' })[1] || [{ imageQuality: 0 }])[0].imageQuality :
                            ''
                        }
                    `
        }, null, 8, _hoisted_4)
      ], 2),
      _createElementVNode("div", {
        class: "q-mt-xl",
        innerHTML: Object.values(Object.values(_ctx.data.customer || { de: [] })[1][0].data.description || { de: '' })[1]
      }, null, 8, _hoisted_5)
    ], 2)
  ], 2))
}