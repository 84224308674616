import { API_Query_Asset } from '../API'

export class CMS_SCHEMA_Person {
    name?: { [key: string]: string }
    position?: { [key: string]: string }
    image?: { [key: string]: API_Query_Asset[] }
    __typename?: string

    constructor () {
        this.name = { iv: '' }
        this.position = { iv: '' }
        this.image = { iv: [] }
        this.__typename = ''
    }
}
