import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "full-width row justify-center" }
const _hoisted_2 = ["title", "width"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["default", "src", "label", "kind", "srclang"]
const _hoisted_5 = {
  key: 0,
  class: "row justify-center q-mt-xl",
  style: {"width":"100%"}
}
const _hoisted_6 = ["title", "width"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src", "label", "kind", "srclang"]
const _hoisted_9 = {
  key: 0,
  class: "row justify-center q-mt-xl",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_cta = _resolveComponent("p-cta")!
  const _component_p_products_element = _resolveComponent("p-products-element")!

  return (['products'].includes(_ctx.type))
    ? (_openBlock(), _createBlock(_component_p_products_element, {
        key: 0,
        headline: _ctx.data.title,
        link: _ctx.$utf8.encode(_ctx.data.title !== null ? _ctx.data.title : '')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.content || [], (element) => {
              return (_openBlock(), _createElementBlock("video", {
                key: element,
                title: (element.data.video.iv || [{ alt: '' }])[0].alt || '',
                style: {"border-radius":"18px"},
                controls: "",
                crossorigin: "anonymous",
                width: `${_ctx.$q.screen.gt.xs ? _ctx.$q.screen.gt.sm ? _ctx.$q.screen.gt.md ? '900' : '700' : '500' : '328'}`
              }, [
                _createElementVNode("source", {
                  src: (element.data.video.iv || [{ url: '' }])[0].url,
                  type: "video/mp4"
                }, null, 8, _hoisted_3),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.data.vtts.iv || [], (vtt) => {
                  return (_openBlock(), _createElementBlock("track", {
                    key: vtt,
                    default: _ctx.$route.meta.lang?.toLowerCase() === vtt.data.srclang.iv.toLowerCase(),
                    src: (vtt.data.vffFile.iv || [{ url: '' }])[0].url,
                    label: Object.values(vtt.data.label)[1],
                    kind: vtt.data.type.iv,
                    srclang: vtt.data.srclang.iv
                  }, null, 8, _hoisted_4))
                }), 128))
              ], 8, _hoisted_2))
            }), 128))
          ]),
          ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_p_cta, {
                  type: _ctx.type,
                  data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
                }, null, 8, ["type", "data"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["headline", "link"]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.content || [], (element) => {
          return (_openBlock(), _createElementBlock("video", {
            key: element,
            title: (element.data.video.iv || [{ alt: '' }])[0].alt || '',
            style: {"border-radius":"18px"},
            controls: "",
            crossorigin: "anonymous",
            width: `${_ctx.$q.screen.gt.xs ? _ctx.$q.screen.gt.sm ? _ctx.$q.screen.gt.md ? '900' : '700' : '500' : '328'}`
          }, [
            _createElementVNode("source", {
              src: (element.data.video.iv || [{ url: '' }])[0].url,
              type: "video/mp4"
            }, null, 8, _hoisted_7),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.data.vtts.iv || [], (vtt) => {
              return (_openBlock(), _createElementBlock("track", {
                key: vtt,
                src: (vtt.data.vffFile.iv || [{ url: '' }])[0].url,
                label: Object.values(vtt.data.label)[1],
                kind: vtt.data.type.iv,
                srclang: vtt.data.srclang.iv
              }, null, 8, _hoisted_8))
            }), 128))
          ], 8, _hoisted_6))
        }), 128)),
        ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_p_cta, {
                type: _ctx.type,
                data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
              }, null, 8, ["type", "data"])
            ]))
          : _createCommentVNode("", true)
      ], 64))
}