
import { defineComponent } from 'vue'
import { CMS_SCHEMA_Histrogramstep } from '@/types/cms/histogram_steps'

export default defineComponent({
    name: 'p_components_pages_histrogramh_step',

    props: {
        data: {
            default: new CMS_SCHEMA_Histrogramstep()
        },
        index: {
            default: 0
        }
    }
})
