import { STATE_Helper } from '@/types/states/helper'
import { store } from 'quasar/wrappers'
import { InjectionKey } from 'vue'
import {
    createStore,
    Store as VuexStore,
    useStore as vuexUseStore
} from 'vuex'

import helper from './modules/helper'

export interface STATE_Root {
    helper: STATE_Helper
}

// provide typings for `this.$store`
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $store: VuexStore<STATE_Root>
    }
}

// provide typings for `useStore` helper
export const storeKey: InjectionKey<VuexStore<STATE_Root>> = Symbol('vuex-key')

export default store(function (/* { ssrContext } */) {
    const Store = createStore<STATE_Root>({
        modules: {
            helper
        }
    })

    return Store
})

export function useStore () {
    return vuexUseStore(storeKey)
}
