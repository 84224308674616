import { API_Query_Asset, API_Query_Data_Object } from '../API'

export class CMS_COMPONENT_Payment {
    name?: string
    label?: string
    description?: string
    logoDark?: API_Query_Asset[]
    logoLight?: API_Query_Asset[]
    __typename: string

    constructor () {
        this.name = ''
        this.label = ''
        this.description = ''
        this.logoDark = []
        this.logoLight = []
        this.__typename = ''
    }
}

export class CMS_SCHEMA_Payments {
    content?: API_Query_Data_Object<CMS_COMPONENT_Payment>[]
    __typename: string

    constructor () {
        this.content = []
        this.__typename = ''
    }
}
