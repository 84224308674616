
import { defineComponent } from 'vue'
import { CMS_COMPONENT_Homepagehero } from '@/types/cms/homepage'

// components
import Menu from '@/components/main/hero/Menu.vue'

export default defineComponent({
    name: 'p_components_main_hero',

    components: {
        'p-menu': Menu
    },

    props: {
        data: {
            default: new CMS_COMPONENT_Homepagehero()
        },
        source_data: {
            default: [new CMS_COMPONENT_Homepagehero()]
        }
    },

    computed: {
        get_menu () {
            return this.source_data.map(x => (x.title))
        }
    }
})
