import { STATE_Helper } from '@/types/states/helper'


function state (): STATE_Helper {
    return {
        dark: {
            is_dark           : false,
            chart_dark_colors : {
                primary  : '#CF2030',
                positive : '#21BA45',
                info     : '#31CCEC',
                negative : '#ff3860',
                c1       : '#333333',
                c2       : '#7A7A7A',
                c3       : '#f2f2f2',
                c4       : '#FFFFFF',
                tl       : {
                    grey   : '#4D4D4D',
                    red    : '#FF3860',
                    yellow : '#FFBE38',
                    green  : '#23D160'
                }
            },
            chart_light_colors: {
                primary  : '#CF2030',
                positive : '#21BA45',
                info     : '#6338FF',
                negative : '#ff3860',
                c1       : '#f2f2f2',
                c2       : '#C8C8C8',
                c3       : '#7A7A7A',
                c4       : '#333333',
                tl       : {
                    grey   : '#4D4D4D',
                    red    : '#FF3860',
                    yellow : '#FFBE38',
                    green  : '#23D160'
                }
            }
        }
    }
}

export default state
