
import { defineComponent } from 'vue'
import { apolloProvider } from '@/boot/apollo'
import { get_short_locale } from '@/boot/i18n'
import { CMS_COMPONENT_Pagesvideopole } from '@/types/cms/pages'
import video_pole_element_video_query from '@/schemas/videopoleelementvideo'
import video_pole_element_link_query from '@/schemas/videopoleelementlink'
import { API_Query_Data, API_Query_Data_Helper } from '@/types/API'
import { CMS_COMPONENT_Videopoleelement, CMS_COMPONENT_Videopoleelementlink, CMS_COMPONENT_Videopoleelementvideo2, CMS_COMPONENT_Videopoleelement_Content } from '@/types/cms/videospole'
import { CMS_COMPONENT_Videos } from '@/types/cms/videos'

// components
import Basic_Products_Element from '@/components/layout/Basic_Products_Element.vue'
import Video_Element from '@/components/pages/video_pole/Video_Element.vue'

export default defineComponent({
    name: 'p_components_pages_video_pole',

    components: {
        'p-products-element' : Basic_Products_Element,
        'p-video-element'    : Video_Element
    },

    props: {
        data: {
            default: new CMS_COMPONENT_Pagesvideopole()
        },
        type: {
            default: ''
        }
    },

    data () {
        const active_opts: CMS_COMPONENT_Videopoleelement_Content[] = []
        return {
            lang           : get_short_locale(this.$route.meta.lang ? this.$route.meta.lang : ''),
            content        : [new CMS_COMPONENT_Videopoleelement()],
            video          : new CMS_COMPONENT_Videos(),
            video_loading  : false,
            active_options : active_opts,
            video_width    : 0,
            video_is_init  : true
        }
    },

    methods: {
        get_options_video_option () {
            apolloProvider.defaultClient.query({
                query       : video_pole_element_video_query(this.lang),
                fetchPolicy : 'cache-first'
            }).then((x: API_Query_Data<API_Query_Data_Helper<CMS_COMPONENT_Videopoleelementvideo2>>) => {
                x.data.data.map((y) => {
                    this.content.push({
                        id          : y.id || '',
                        label       : Object.values(y.data.label)[1],
                        video       : y.data.video.iv[0].data,
                        options     : y.data.options.iv,
                        description : Object.values(y.data.description)[1],
                        __typename  : y.data.__typename
                    })
                })
            }).catch((e) => {
                console.error(e)
            })
        },

        get_options_link_option () {
            apolloProvider.defaultClient.query({
                query       : video_pole_element_link_query(this.lang),
                fetchPolicy : 'cache-first'
            }).then((x: API_Query_Data<API_Query_Data_Helper<CMS_COMPONENT_Videopoleelementlink>>) => {
                x.data.data.map((y) => {
                    this.content.push({
                        id          : y.id || '',
                        label       : Object.values(y.data.label)[1],
                        link        : Object.values(y.data.link)[1],
                        description : Object.values(y.data.description)[1],
                        __typename  : y.data.__typename
                    })
                })
            }).catch((e) => {
                console.error(e)
            })
        },

        set_init_video () {
            this.video = this.data.content[0].data.video.iv[0].data
            this.active_options = []
            this.active_options = this.data.content[0].data.options.iv

            setTimeout(() => {
                this.get_video_element()
            }, 300)
        },

        set_new_video (option: CMS_COMPONENT_Videopoleelement) {
            this.video_is_init = false
            this.video_loading = true
            this.video = option.video || new CMS_COMPONENT_Videos()
            this.active_options = []
            this.active_options = option.options || []

            setTimeout(() => {
                this.video_loading = false
            }, 300)
        },

        get_video_element () {
            const doc = this.$refs.video_element
            // @ts-ignore
            if (doc && doc.focus) {
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                this.video_width = doc.clientWidth
            }
        }
    },

    watch: {
        '$q.screen.width': {
            handler () {
                this.get_video_element()
            },
            immediate: true
        }
    },

    mounted () {
        this.set_init_video()
        this.content = []
        this.get_options_video_option()
        this.get_options_link_option()
    }
})
