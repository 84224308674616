

import { defineComponent } from 'vue'
import { useQuasar, useMeta } from 'quasar'
// import { useStore } from '@/store'
import { modeColors } from '@/helper/dark_mode_colors'
import meta_config from './meta_config'

export default defineComponent({
    name: 'App',
    setup () {
        const $q = useQuasar()

        // language corruptions checker
        const language: string | null = $q.localStorage.getItem('language')
        if (language !== null && language.length === 2) {
            $q.localStorage.remove('language')
        }

        useMeta(meta_config)
    },

    watch: {
        '$route.meta.lang': {
            handler (new_value: string) {
                this.$i18n.locale = new_value
            },
            immediate: true
        }
    },

    mounted () {
        // dark mode
        const dark_mode: string | null = this.$q.localStorage.getItem('dark_mode')
        if (!dark_mode || dark_mode === null) {
            this.$q.localStorage.set('dark_mode', 'auto')

            this.$q.dark.set('auto')
        } else {
            let new_mode: boolean | 'auto' = 'auto'
            if (dark_mode === 'true') new_mode = true
            if (dark_mode === 'false') new_mode = false

            this.$q.dark.set(new_mode)
        }

        setTimeout(() => {
            modeColors(this.$q.dark.isActive)
        }, 5)

        this.$store.dispatch('helper/A_TOGGLE_DARK', this.$q.dark.isActive)
    }
})
