import gql from 'graphql-tag'

export default (lang: string) => {
    return gql`query QUERY_VIDEOPOLE_VIDEO_ELEMENT_CONTENT {
        data: queryVideopoleelementvideoContents {
            id
            data {
                label {
                    ${lang}
                }
                description {
                    ${lang}
                }
                options {
                    iv {
                        ... on Videopoleelmentlink {
                            id
                        }
                        ... on Videopoleelementvideo {
                            id
                        }
                    }
                }
                video {
                    iv {
                        data {
                            name {
                                iv
                            }
                            video {
                                iv {
                                    url
                                }
                            }
                            vtts {
                                iv {
                                    data {
                                        label {
                                            ${lang}
                                        }
                                        srclang {
                                            iv
                                        }
                                        type {
                                            iv
                                        }
                                        vffFile {
                                            iv {
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }`
}
