import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row justify-start no-wrap" }
const _hoisted_2 = ["alt", "title", "src"]
const _hoisted_3 = ["alt", "title", "src"]
const _hoisted_4 = { class: "text-subtitle1 q-mt-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_p_tooltip = _resolveComponent("p-tooltip")!
  const _component_q_card = _resolveComponent("q-card")!
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createBlock(_component_q_card, {
    flat: "",
    class: "bg-transparent q-ma-md",
    style: `max-width: 300px; overflow: hidden`
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, {
        class: "column justify-center items-center",
        style: {"overflow":"hidden"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.$q.dark.isActive)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  loading: "lazy",
                  alt: (_ctx.data.logoDark || [{ alt: '' }])[0].alt || '',
                  title: (_ctx.data.logoDark || [{ alt: '' }])[0].alt || '',
                  src: `
                        ${(_ctx.data.logoDark || [{ url: '' }])[0].url || ''}${
                            Object.values(_ctx.data.logoDark || [{ imageQuality: 0 }])[0].imageQuality ?
                            '?quality=' + Object.values(_ctx.data.logoDark || [{ imageQuality: 0 }])[0].imageQuality :
                            ''
                        }
                    `,
                  width: "125",
                  height: "75"
                }, null, 8, _hoisted_2))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  loading: "lazy",
                  width: "125",
                  height: "75",
                  alt: (_ctx.data.logoLight || [{ alt: '' }])[0].alt || '',
                  title: (_ctx.data.logoLight || [{ alt: '' }])[0].alt || '',
                  src: `
                        ${(_ctx.data.logoLight || [{ url: '' }])[0].url || ''}${
                            Object.values(_ctx.data.logoLight || [{ imageQuality: 0 }])[0].imageQuality ?
                            '?quality=' + Object.values(_ctx.data.logoLight || [{ imageQuality: 0 }])[0].imageQuality :
                            ''
                        }
                    `
                }, null, 8, _hoisted_3))
          ]),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.data.label), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_p_tooltip, {
        data: _ctx.data.description
      }, null, 8, ["data"])
    ]),
    _: 1
  })), [
    [_directive_ripple, { color: 'primary' }]
  ])
}