import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "row justify-center",
  style: {"width":"100%"}
}
const _hoisted_2 = {
  class: "row justify-evenly",
  style: {"width":"calc(100% + 48px)","margin-left":"-24px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_basic_element = _resolveComponent("p-basic-element")!

  return (_openBlock(), _createBlock(_component_p_basic_element, {
    headline: _ctx.data.title,
    link: _ctx.data.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.persons || [], (element) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(element.__typename)), {
              key: element,
              class: "q-ma-xl",
              data: element.data
            }, null, 8, ["data"]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["headline", "link"]))
}