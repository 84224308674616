import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0410525e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "q-mb-xs" }
const _hoisted_2 = ["alt", "title", "src"]
const _hoisted_3 = {
  class: "text-caption",
  style: {"width":"180px","text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    flat: "",
    style: {"border-radius":"18px","min-height":"202px"},
    clickable: "",
    class: _normalizeClass(["bg-accent justify-center items-center q-pa-md", {
            'column': _ctx.$q.screen.gt.sm,
            'row': _ctx.$q.screen.lt.md
        }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          alt: Object.values(_ctx.data.icon || [{ alt: '' }])[1][0].alt || '',
          title: Object.values(_ctx.data.icon || [{ alt: '' }])[1][0].alt || '',
          loading: "lazy",
          width: "100",
          height: "100",
          style: {"height":"auto","width":"100px"},
          src: Object.values(_ctx.data.icon || [{ url: '' }])[1][0].url || ''
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(Object.values(_ctx.data.description || [])[1]), 1),
      _createElementVNode("div", {
        class: _normalizeClass([{
                'indexIndicatorDark': _ctx.$q.dark.isActive
            }, "indexIndicator"])
      }, _toDisplayString(_ctx.index + 1) + ". ", 3)
    ]),
    _: 1
  }, 8, ["class"]))
}