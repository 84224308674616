import { setCssVar } from 'quasar'

/*
    Light mode colors are set by default in brand config.
    Only chaning colors are defined here.
*/

export const modeColors = (dark: boolean) => {
    if (dark) {
        setCssVar('info', '#31CCEC')
        setCssVar('link', '#D78FDA')
        setCssVar('accent', '#3a343a')
        setCssVar('accent2', '#252225')
        setCssVar('accent3', '#2b272b')
        setCssVar('text', '#FFFFFF')
        setCssVar('text2', '#F5F5F5')
        setCssVar('text3', '#7A7A7A')
    } else {
        setCssVar('info', '#6338FF')
        setCssVar('link', '#a645b5')
        setCssVar('accent', '#fffcff')
        setCssVar('accent2', '#f1e8f1')
        setCssVar('accent3', '#ddccdd')
        setCssVar('text', '#000000')
        setCssVar('text2', '#202124')
        setCssVar('text3', '#333333')
    }
}
