import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_tooltip = _resolveComponent("p-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (['products'].includes(_ctx.type))
    ? (_openBlock(), _createBlock(_component_q_btn, {
        key: 0,
        class: "p-button",
        color: "primary",
        size: "md",
        padding: "md lg",
        "aria-label": Object.values(_ctx.data.label || { de: '' })[1],
        rel: "noreferrer",
        href: Object.values(_ctx.data.link || { de: '' })[1],
        target: Object.values(_ctx.data.newPage || { de: '' })[1] ? '__blank' : '',
        "no-caps": "",
        style: {"border-radius":"18px"},
        label: Object.values(_ctx.data.label || { de: '' })[1]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_p_tooltip, {
            data: Object.values(_ctx.data.description || { de: '' })[1]
          }, null, 8, ["data"])
        ]),
        _: 1
      }, 8, ["aria-label", "href", "target", "label"]))
    : (_openBlock(), _createBlock(_component_q_btn, {
        key: 1,
        class: "p-button",
        color: "primary",
        size: "md lg",
        padding: "md lg",
        rel: "noreferrer",
        "aria-label": Object.values(_ctx.data.label || { de: '' })[1],
        href: Object.values(_ctx.data.link || { de: '' })[1],
        target: Object.values(_ctx.data.newPage || { de: '' })[1] ? '__blank' : '',
        "no-caps": "",
        style: {"border-radius":"18px"},
        label: Object.values(_ctx.data.label || { de: '' })[1]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_p_tooltip, {
            data: Object.values(_ctx.data.description || { de: '' })[1]
          }, null, 8, ["data"])
        ]),
        _: 1
      }, 8, ["aria-label", "href", "target", "label"]))
}