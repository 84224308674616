import { boot } from 'quasar/wrappers'


declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $cms_to_page_focus: (x: number, y: number) => string
    }
}

const cms_to_page_focus = (x: number, y: number) => {
    const value = (val: number) => {
        return 50 + (50 * val)
    }
    return `${value(x)}% ${value(-y)}% `
}

export default boot(({ app }) => {
    app.config.globalProperties.$cms_to_page_focus = cms_to_page_focus
})

export { cms_to_page_focus }
