
import { defineComponent } from 'vue'

// components
import { CMS_COMPONENT_Videos } from '@/types/cms/videos'

export default defineComponent({
    name: 'p_components_pages_video_pole_video_element',

    emits: ['play'],

    data () {
        return {
            muted            : true,
            init_muted       : false,
            init_muted_pulse : false
        }
    },

    methods: {
        click_on_init () {
            this.init_muted = false
            this.init_muted_pulse = false
            this.muted = false
        },

        pulse () {
            if (this.init && this.muted) {
                this.init_muted = true
            }
            if (this.init_muted) {
                if (this.init_muted_pulse) {
                    setTimeout(() => {
                        this.init_muted_pulse = false
                        this.pulse()
                    }, 3000)
                } else {
                    setTimeout(() => {
                        this.init_muted_pulse = true
                        this.pulse()
                    }, 1000)
                }
            } else {
                this.init_muted = false
                this.muted = false
            }
        },

        onplay () {
            this.$emit('play')
        }
    },

    props: {
        video: {
            default: new CMS_COMPONENT_Videos()
        },
        init: {
            default : false,
            type    : Boolean
        }
    },

    mounted () {
        this.pulse()
    }
})
