import { API_Query_Asset } from '../API'

export class CMS_SCHEMA_Histrogramstep {
    description?: { [key: string]: string }
    icon?: { [key: string]: API_Query_Asset[] }
    __typename?: string

    constructor () {
        this.description = { de: '' }
        this.icon = { de: [] }
        this.__typename = ''
    }
}
