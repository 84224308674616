import { boot } from 'quasar/wrappers'
import Moment from 'moment'
import 'moment/locale/de'

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $m: (inp?: moment.MomentInput, format?: string | moment.MomentBuiltinFormat | (string | moment.MomentBuiltinFormat)[] | undefined, strict?: boolean | undefined) => moment.Moment
    }
}

const moment = Moment

export default boot(({ app, urlPath }) => {
    const lang = urlPath.split('/')[1].split('#')[0].split('-')[0]
    moment.locale(lang)

    app.config.globalProperties.$m = moment
})

export { moment }
