import { API_Query_Asset, API_Query_Data_Object, API_Query_Data_Helper } from '../API'
import { CMS_SCHEMA_Calltoactions } from './calltoactions'
import { CMS_COMPONENT_Ref_Content } from './components/ref_content'
import { CMS_COMPONENT_Routerinfo } from './components/routing'
import { CMS_COMPONENT_Seo } from './components/seo'
import { CMS_SCHEMA_Editor } from './editor'
import { CMS_SCHEMA_Grid_Element_Text, CMS_SCHEMA_Grid_Element_Text_Icon } from './grid_elements'
import { CMS_SCHEMA_Histrogramstep } from './histogram_steps'
import { CMS_SCHEMA_Icons } from './icons'
import { CMS_SCHEMA_Payments } from './payments'
import { CMS_COMPONENT_Videos } from './videos'
import { CMS_COMPONENT_Videopoleelementvideo2 } from './videospole'

export class CMS_COMPONENT_Pagesprice {
    title?: string | null
    priceSubText?: string
    price?: string
    hint?: string
    cta?: API_Query_Data_Helper<CMS_SCHEMA_Calltoactions>[]
    __typename: string

    constructor () {
        this.title = ''
        this.priceSubText = ''
        this.price = ''
        this.cta = []
        this.__typename = ''
        this.hint = ''
    }
}

export class CMS_COMPONENT_Pageseditorad {
    title?: string | null
    person?: API_Query_Data_Helper<CMS_SCHEMA_Editor>[]
    cta?: API_Query_Data_Helper<CMS_SCHEMA_Calltoactions>[]
    __typename: string

    constructor () {
        this.title = ''
        this.person = []
        this.cta = []
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Pageslinks {
    title?: string | null
    pages?: API_Query_Data_Helper<CMS_SCHEMA_Pages>[]
    __typename: string

    constructor () {
        this.title = ''
        this.pages = []
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Pagespayments {
    title?: string | null
    content?: API_Query_Data_Object<CMS_SCHEMA_Payments>[]
    cta?: API_Query_Data_Helper<CMS_SCHEMA_Calltoactions>[]
    __typename: string

    constructor () {
        this.title = ''
        this.content = []
        this.cta = []
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Pagestextimage {
    title?: string | null
    content?: string
    image?: API_Query_Asset[]
    imageLeft?: boolean
    cta?: API_Query_Data_Helper<CMS_SCHEMA_Calltoactions>[]
    __typename: string

    constructor () {
        this.title = ''
        this.content = ''
        this.imageLeft = false
        this.image = []
        this.cta = []
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Pagesgridlistelement {
    description?: string
    icon?: API_Query_Data_Object<CMS_SCHEMA_Icons>[]
    __typename: string

    constructor () {
        this.description = ''
        this.icon = []
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Pagesgridlist {
    title?: string | null
    content?: API_Query_Data_Object<CMS_COMPONENT_Pagesgridlistelement>[]
    cta?: API_Query_Data_Helper<CMS_SCHEMA_Calltoactions>[]
    __typename: string

    constructor () {
        this.title = ''
        this.content = []
        this.cta = []
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Pagesimage {
    title?: string | null
    imageDark?: API_Query_Asset[]
    imageLight?: API_Query_Asset[]
    cta?: API_Query_Data_Helper<CMS_SCHEMA_Calltoactions>[]
    __typename: string
    fullWidth?: boolean

    constructor () {
        this.title = ''
        this.imageDark = []
        this.imageLight = []
        this.cta = []
        this.__typename = ''
        this.fullWidth = false
    }
}

export class CMS_COMPONENT_Pagesvideo {
    title?: string | null
    video?: API_Query_Asset[]
    content?: { data: CMS_COMPONENT_Videos }[]
    cta?: API_Query_Data_Helper<CMS_SCHEMA_Calltoactions>[]
    __typename: string

    constructor () {
        this.title = ''
        this.video = []
        this.content = []
        this.cta = []
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Pagesvideopole {
    title?: string | null
    content: { data: CMS_COMPONENT_Videopoleelementvideo2 }[]
    __typename: string

    constructor () {
        this.title = ''
        this.content = []
        this.__typename = ''
    }
}

export type CMS_COMPONENT_Pagesgrid_Content =
    CMS_SCHEMA_Grid_Element_Text |
    CMS_SCHEMA_Grid_Element_Text_Icon

export class CMS_COMPONENT_Pagesgrid {
    title?: string | null
    content?: API_Query_Data_Object<CMS_COMPONENT_Pagesgrid_Content>[]
    cta?: API_Query_Data_Helper<CMS_SCHEMA_Calltoactions>[]
    __typename: string

    constructor () {
        this.title = ''
        this.content = []
        this.cta = []
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Pageshistrogramh {
    title?: string | null
    content?: API_Query_Data_Object<CMS_SCHEMA_Histrogramstep>[]
    cta?: API_Query_Data_Helper<CMS_SCHEMA_Calltoactions>[]
    __typename: string

    constructor () {
        this.title = ''
        this.content = []
        this.__typename = ''
    }
}


export class CMS_COMPONENT_Pagestext {
    title?: string | null
    content?: string
    cta?: API_Query_Data_Helper<CMS_SCHEMA_Calltoactions>[]
    __typename: string

    constructor () {
        this.title = ''
        this.content= ''
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Pageshero {
    title?: string | null
    subline?: string
    headline?: string
    background?: API_Query_Asset[]
    __typename: string

    constructor () {
        this.title = ''
        this.subline = ''
        this.headline = ''
        this.background = []
        this.__typename = ''
    }
}

export type CMS_SCHEMA_Pages_Content =
    CMS_COMPONENT_Pageshero |
    CMS_COMPONENT_Pagestext |
    CMS_COMPONENT_Pageshistrogramh |
    CMS_COMPONENT_Pagesgrid |
    CMS_COMPONENT_Pagesvideo |
    CMS_COMPONENT_Pagesimage |
    CMS_COMPONENT_Pagesgridlist |
    CMS_COMPONENT_Pagestextimage |
    CMS_COMPONENT_Pageslinks |
    CMS_COMPONENT_Pageseditorad

export class CMS_SCHEMA_Pages {
    id?: string
    name?: { iv: string }
    routing?: { [key: string]: CMS_COMPONENT_Routerinfo }
    vueComponent?: { [key: string]: string }
    seo?: { [key: string]: CMS_COMPONENT_Seo }
    refContent?: { [key: string]: CMS_COMPONENT_Ref_Content }
    content?: CMS_SCHEMA_Pages_Content[]

    constructor () {
        this.name = { iv: '' }
        this.routing = { de: new CMS_COMPONENT_Routerinfo() }
        this.vueComponent = { de: '' }
        this.seo = { de: new CMS_COMPONENT_Seo() }
        this.refContent = { de: new CMS_COMPONENT_Ref_Content() }
        this.content = []
    }
}
