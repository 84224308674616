import { API_Query_Asset } from '../API'

export class CMS_SCHEMA_Grid_Element_Text {
    content?: string
    __typename?: string
}


export class CMS_SCHEMA_Grid_Element_Text_Icon {
    content?: string
    icon?: API_Query_Asset[]
    __typename?: string

    constructor () {
        this.content = ''
        this.icon = []
        this.__typename = ''
    }
}
