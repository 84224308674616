import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22fbe69f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column justify-center items-center" }
const _hoisted_2 = ["alt", "title", "src"]
const _hoisted_3 = {
  class: "q-mb-xs text-subtitle1",
  style: {"line-height":"120%"}
}
const _hoisted_4 = {
  class: "text-overline text-weight-light",
  style: {"line-height":"120%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, {
      class: _normalizeClass(["p-card no-shadow", {
                'bg-accent': !_ctx.$q.dark.isActive,
                'bg-accent': _ctx.$q.dark.isActive
            }]),
      style: `width: 200px; border-radius: 18px`
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, {
          class: "q-pa-none bg-accent3",
          style: {"width":"100%","height":"200px"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              loading: "lazy",
              width: "250",
              class: "p-team-image",
              alt: (Object.values(_ctx.data.image || { iv: [] })[1][0] || { alt: '' }).alt || '',
              title: (Object.values(_ctx.data.image || { iv: [] })[1][0] || { alt: '' }).alt || '',
              src: `
                        ${ (Object.values(_ctx.data.image || { iv: [] })[1][0] || [{ url: '' }]).url || '' }${
                            (Object.values(_ctx.data.image || { iv: [] })[1][0] || [{ imageQuality: 0 }]).imageQuality ?
                            '?quality=' + (Object.values(_ctx.data.image || { iv: [] })[1][0] || [{ imageQuality: 0 }]).imageQuality :
                            ''
                        }
                    `
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }),
        _createVNode(_component_q_card_section, {
          class: "column justify-start",
          style: {"overflow":"hidden","min-height":"100px"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, _toDisplayString(Object.values(_ctx.data.name || {})[1]), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString((Object.values(_ctx.data.position || {})[1] || '').toUpperCase()), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}