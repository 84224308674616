import { API_Query_Asset } from '@/types/API'

export class CMS_COMPONENT_Seo {
    title: string
    description: string
    keywords: string[]
    ogTitle: string
    ogImage: API_Query_Asset[]
    robots: 'noindex' | 'nofollow' | 'noindex, nofollow' | ''

    constructor () {
        this.title = ''
        this.description = ''
        this.keywords = []
        this.robots = ''
        this.ogTitle = ''
        this.ogImage = []
    }
}
