import { boot } from 'quasar/wrappers'
import { get_valid_locale, kown_shorts } from '@/boot/i18n'

export default boot(({ app, router }) => {
    const routes = router.getRoutes().map(x => x)

    const get_sister_route = (route_name: string, lang?: string) => {
        const language = get_valid_locale(lang || '')
        const route_raw = routes.find(x => x.name === route_name)
        const route = route_raw && route_raw !== null ? route_raw : { meta: { type: 'main', lang: language } }
        const new_lang = lang && lang !== '' ? language : route.meta.lang
        let new_route_name = 'main_de-de'
        let found_route = false

        routes.map(x => {
            if (!found_route) {
                if (x.meta.type === route.meta.type) {
                    if (get_valid_locale(x.meta.lang || '') === new_lang) {
                        new_route_name = (x.name || '').toString()
                        found_route = true
                    }
                    new_route_name = (x.name || '').toString()
                }
            }
        })

        return new_route_name
    }

    router.beforeEach((to, from, next) => {
        /* if (to.name === 'root') {
            next({ name: get_sister_route((from.name || '').toString()) })
        } */

        const lowered_path = to.path.toLowerCase()
        if (lowered_path === to.path) next()

        next(lowered_path)
    })

    router.beforeResolve((to, from, next) => {
        /* if (to.params && to.params.lang && to.meta && to.meta.lang && to.params.lang !== to.meta.lang) {
            const found_route = router.getRoutes().find(x => (x.meta.type === to.meta.type && x.meta.lang === to.params.lang))
            if (!found_route || found_route === null) {
                next()
            }ß
            const new_route = {
                name   : found_route?.name,
                params : Object.assign(to.params, { lang: found_route?.meta.lang })
            }
            next(new_route)
        } */

        if (!to.name) {
            const lang_catch = to.params.catchAll[0]

            // unknown language catch
            if (lang_catch.length !== 5) {
                const old_path_array = to.path.split('/')
                old_path_array.splice(1, 1, get_valid_locale(lang_catch))
                const new_path = `${old_path_array.join('/')}`

                next({ path: new_path })

            // unknown route but language catch is valid
            } else {
                let found_route = { path: to.path, meta: { lang: get_valid_locale(lang_catch), type: '' } }

                // path catch with different language
                kown_shorts.map(lang => {
                    const language = get_valid_locale(lang)
                    const old_path_array = to.path.split('/')
                    old_path_array.splice(1, 1, language)
                    const new_path = old_path_array.join('/')

                    const found = routes.find(x => (x.path === new_path))

                    if (found && found !== null) {
                        // @ts-ignore
                        found_route = found
                    }
                })

                if (found_route.path !== to.path) {
                    if (get_valid_locale(lang_catch) !== found_route.meta.lang) {
                        const found = routes.find(x => {
                            return (
                                x.meta.type === found_route.meta.type &&
                                x.meta.lang === get_valid_locale(lang_catch)
                            )
                        })

                        if (found && found !== null) next({ name: found.name })
                    }
                }
            }
        }

        next()
    })

    app.config.globalProperties.$get_sister_route = get_sister_route
})

