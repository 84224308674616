
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'p_components_empty',

    props: {
        data: {
            default  : {},
            required : false
        }
    }
})
