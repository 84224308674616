
import { CMS_SCHEMA_Payments } from '@/types/cms/payments'
import { defineComponent } from 'vue'

// components
import Payment from '@/components/pages/payments/Payment.vue'

export default defineComponent({
    name: 'p_components_pages_payments_el',

    components: {
        'p-payment': Payment
    },

    props: {
        data: {
            default: new CMS_SCHEMA_Payments()
        },
        type: {
            default: ''
        }
    }
})
