import { API_Query_Asset } from '@/types/API'

export class CMS_COMPONENT_Ref_Content {
    label: string
    label_short: string
    description: string
    descriptionShort: string
    image: API_Query_Asset[] | null

    constructor () {
        this.label = ''
        this.label_short = ''
        this.description = ''
        this.descriptionShort = ''
        this.image = null
    }
}
