
import { CMS_SCHEMA_Pages } from '@/types/cms/pages'
import { defineComponent } from 'vue'

// components

export default defineComponent({
    name: 'p_components_pages_page',

    props: {
        data: {
            default: new CMS_SCHEMA_Pages()
        }
    }
})
