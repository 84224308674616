import { API_Query_Data_Helper, API_Query_Data_Object } from '../API'
import { CMS_SCHEMA_Calltoactions } from './calltoactions'
import { CMS_COMPONENT_Seo } from './components/seo'
import { CMS_SCHEMA_Customers } from './customers'
import { CMS_SCHEMA_Person } from './person'
import { CMS_SCHEMA_Products } from './products'
import { CMS_SCHEMA_Reviews } from './reviews'

export class CMS_COMPONENT_Homepageteam {
    title?: string
    persons?: API_Query_Data_Object<CMS_SCHEMA_Person>
    __typename?: string

    constructor () {
        this.title = ''
        this.persons = { data: [] }
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Homepagereviews {
    title?: string
    reviews?: API_Query_Data_Object<CMS_SCHEMA_Reviews>
    cta?: API_Query_Data_Helper<CMS_SCHEMA_Calltoactions>[]
    __typename?: string

    constructor () {
        this.title = ''
        this.reviews = { data: [] }
        this.cta = []
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Homepagecustomers {
    title?: string
    customers?: API_Query_Data_Object<CMS_SCHEMA_Customers>
    __typename?: string

    constructor () {
        this.title = ''
        this.customers = { data: [] }
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Homepageproducts {
    title?: string
    products?: API_Query_Data_Object<CMS_SCHEMA_Products>
    __typename?: string

    constructor () {
        this.title = ''
        this.products = { data: [] }
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Homepagehero {
    title?: string
    label?: string
    copyright?: string
    prefix?: string
    suffix?: string
    __typename?: string

    constructor () {
        this.title = ''
        this.label = ''
        this.copyright = ''
        this.prefix = ''
        this.suffix = ''
        this.__typename = ''
    }
}

export type CMS_SCHEMA_Homepage_Content =
    CMS_COMPONENT_Homepagehero |
    CMS_COMPONENT_Homepageproducts |
    CMS_COMPONENT_Homepagecustomers |
    CMS_COMPONENT_Homepagereviews |
    CMS_COMPONENT_Homepageteam

export class CMS_SCHEMA_Homepage {
    seo?: { [key: string]: CMS_COMPONENT_Seo }
    content?: CMS_SCHEMA_Homepage_Content[]
    __typename?: string

    constructor () {
        this.seo = { de: new CMS_COMPONENT_Seo() }
        this.content = []
        this.__typename = ''
    }
}
