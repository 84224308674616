import { ActionTree } from 'vuex'
import { STATE_Root } from '@/store/index'
import { STATE_Helper } from '@/types/states/helper'

const actions: ActionTree<STATE_Helper, STATE_Root> = {
    A_TOGGLE_DARK ({ commit }, active: boolean) {
        commit('M_SET_DARK', active)
    }
}

export default actions
