
import { defineComponent } from 'vue'
import { CMS_SCHEMA_Calltoactions } from '@/types/cms/calltoactions'

// components
import Tooltip from '@/components/Tooltip.vue'


export default defineComponent({
    name: 'p_components_pages_cta',

    components: {
        'p-tooltip': Tooltip
    },

    props: {
        data: {
            default: new CMS_SCHEMA_Calltoactions()
        },
        type: {
            default: ''
        }
    }
})
