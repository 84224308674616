
import { API_Query_Asset } from '@/types/API'
import { CMS_SCHEMA_Customers } from '@/types/cms/customers'
import { defineComponent } from 'vue'

// components

export default defineComponent({
    name: 'p_components_main_customers_customer',

    /* components: {
        'p-tooltip': Tooltip
    }, */

    props: {
        data: {
            default: new CMS_SCHEMA_Customers()
        },
        index: {
            default: 0
        },
        data_length: {
            default: 0
        }
    },

    data () {
        return {
            is_hover : false,
            open     : false,
            is_blink : false
        }
    },

    methods: {
        on_hover (status: boolean) {
            this.is_hover = status
        },

        image_parser (obj?: { [key: string]: API_Query_Asset[] }) {
            if (obj !== null && (obj || {}).iv.length > 0) {
                return {
                    url    : (obj || {}).iv[0].url || '',
                    height : (obj || {}).iv[0].pixelHeight || 0,
                    width  : (obj || {}).iv[0].pixelWidth || 0,
                    alt    : (obj || {}).iv[0].alt || ''
                }
            } else {
                return {
                    url    : '',
                    height : 0,
                    width  : 0
                }
            }
        },

        p_open (value: boolean) {
            this.open = value
            this.is_hover = true
        },

        p_dialog_close () {
            this.is_hover = false
        },

        async p_blink () {
            const timeout = this.data_length > 0 ? (this.data_length * 1000) - 2000 : 3000
            const loop = async (index: number) => {
                return new Promise((res) => {
                    setTimeout(() => {
                        this.is_blink = true

                        setTimeout(() => {
                            this.is_blink = false

                            res(true)
                        }, 500)
                    }, index > 0 ? timeout : 500)
                })
            }

            for await (let i of [...Array(1000).keys()]) {
                await loop(i)
            }
        }
    },

    mounted () {
        const delay = this.index * 600
        setTimeout(() => {
            this.p_blink()
        }, delay)
    }
})
