import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row justify-center q-mt-md",
  style: {"width":"100%"}
}
const _hoisted_2 = {
  key: 0,
  class: "row justify-center q-mt-md",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_payments = _resolveComponent("p-payments")!
  const _component_p_cta = _resolveComponent("p-cta")!
  const _component_p_products_element = _resolveComponent("p-products-element")!

  return (['products'].includes(_ctx.type))
    ? (_openBlock(), _createBlock(_component_p_products_element, {
        key: 0,
        headline: _ctx.data.title,
        link: _ctx.$utf8.encode(_ctx.data.title !== null ? _ctx.data.title : ''),
        custom_padding: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_p_payments, {
            data: (_ctx.data.content || [])[0].data,
            type: _ctx.type
          }, null, 8, ["data", "type"]),
          ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_p_cta, {
                  type: _ctx.type,
                  data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
                }, null, 8, ["type", "data"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["headline", "link"]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_p_payments),
        ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_p_cta, {
                type: _ctx.type,
                data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
              }, null, 8, ["type", "data"])
            ]))
          : _createCommentVNode("", true)
      ], 64))
}