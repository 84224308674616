import { API_Query_Asset } from '../API'

export class CMS_COMPONENT_Videos_VTT {
    label: { [key: string]: string }
    srclang: { iv: string }
    type: { iv: string }
    vffFile: { iv: API_Query_Asset[] }

    constructor () {
        this.label = { iv: '' }
        this.srclang = { iv: '' }
        this.type = { iv: '' }
        this.vffFile = { iv: [] }
    }
}

export class CMS_COMPONENT_Videos {
    name: { iv: string }
    video: { iv: API_Query_Asset[] }
    vtts: { iv: { data: CMS_COMPONENT_Videos_VTT }[] }
    __typename: string

    constructor () {
        this.name = { iv: '' }
        this.video = { iv: [] }
        this.vtts = { iv: [] }
        this.__typename = ''
    }
}
