import gql from 'graphql-tag'

export default (lang: string) => {
    return gql`query QUERY_VIDEOPOLE_LINK_ELEMENT_CONTENT {
        data: queryVideopoleelmentlinkContents {
            id
            data {
                label {
                    ${lang}
                }
                description {
                    ${lang}
                }
                link {
                    ${lang}
                }
            }
        }
    }`
}
