import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19ea441e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return true
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("div", {
          class: _normalizeClass(["q-mt-md bg-accent column align-center justify-center p-hero-hover", {
                'q-mx-lg': _ctx.$q.screen.gt.sm,
                'q-mx-md': _ctx.$q.screen.lt.md
            }]),
          style: _normalizeStyle(`
                width: 100%;
                border-radius: 18px;
                height: ${_ctx.$q.screen.lt.md ? '30vh' : '50vh'};
                max-height: 600px;
                position: relative`)
        }, [
          _createElementVNode("div", {
            class: "column items-start justify-end",
            title: (_ctx.data.background || [{ alt: '' }])[0].alt || '',
            style: _normalizeStyle(`
                        overflow: hidden;
                        border-radius: 18px;
                        background-size: cover;
                        background-position: ${_ctx.$cms_to_page_focus((_ctx.data.background || [{ focusX: 0 }])[0].focusX || 0, (_ctx.data.background || [{ focusY: 0 }])[0].focusY || 0)};
                        height: 100%;
                        background-image: url(
                            ${(_ctx.data.background || [{ url: '' }])[0].url || ''}${
                                (_ctx.data.background || [{ imageQuality: 0 }])[0].imageQuality ?
                                '?quality=' + (_ctx.data.background || [{ imageQuality: 0 }])[0].imageQuality :
                                ''
                            }
                        )`)
          }, null, 12, _hoisted_1)
        ], 6),
        _createElementVNode("div", {
          class: _normalizeClass(["row justify-between q-mb-xl", {
                'row q-mx-lg': _ctx.$q.screen.gt.sm,
                'column q-mx-md': _ctx.$q.screen.lt.md
            }]),
          style: {"width":"100%"}
        }, [
          _createElementVNode("div", {
            class: _normalizeClass({
                    'q-mx-sm': _ctx.$q.screen.lt.md
                }),
            style: _normalizeStyle(`
                    flex: 1;
                    transform: translate3d(${ _ctx.$q.screen.gt.md ? '5%, -50%, 0' : '0, -50%, 0' });
                    padding-right: ${ _ctx.$q.screen.gt.md ? '5%' : '0' };
                    display: flex;
                    justify-content: ${ _ctx.$q.screen.gt.md ? 'flex-start' : 'center' };
                    align-items: center`)
          }, [
            _createElementVNode("h1", {
              style: _normalizeStyle(`
                        z-index: 4;
                        font-size: ${ _ctx.$q.screen.gt.md ? '3rem' : '1.5rem' };
                        line-height: ${ _ctx.$q.screen.gt.md ? '3.5rem' : '2rem' }`),
              class: "text-h1 text-primary text-weight-medium q-pa-lg p-head-box"
            }, _toDisplayString(_ctx.data.headline), 5)
          ], 6),
          _createElementVNode("div", {
            style: {"z-index":"4","flex":"1","word-break":"break-word"},
            class: _normalizeClass({
                    'q-ml-xl q-mt-lg q-pa-md': _ctx.$q.screen.gt.sm
                }),
            innerHTML: _ctx.data.subline
          }, null, 10, _hoisted_2)
        ], 2)
      ], 64))
    : _createCommentVNode("", true)
}