import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (element) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: element }, [
        (element !== 'root')
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 0,
              class: "text-text2 text-weight-medium",
              "aria-label": element,
              label: element,
              to: { name: _ctx.$route.name, hash: `#${element}`, meta: _ctx.$route.meta },
              flat: ""
            }, null, 8, ["aria-label", "label", "to"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}