import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fit column justify-between items-center no-wrap" }
const _hoisted_2 = { class: "p-price-headline row justify-center no-wrap" }
const _hoisted_3 = {
  sttyle: "height: 100%",
  class: "column justify-between items-center"
}
const _hoisted_4 = { class: "p-price column justify-start items-center" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "row justify-center",
  style: {"width":"100%","z-index":"19"}
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  height: "500",
  width: "260",
  alt: "Man presenting price tag.",
  title: "Man presenting price tag.",
  class: "p-price-announcer",
  src: "https://cms.pagisto.com/api/assets/pagisto-com/80754e93-722d-4547-ab64-a5c91746f3a3/price-boy.svg?version=0"
}
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_cta = _resolveComponent("p-cta")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_p_products_element = _resolveComponent("p-products-element")!

  return (['products'].includes(_ctx.type))
    ? (_openBlock(), _createBlock(_component_p_products_element, {
        key: 0,
        headline: _ctx.data.title,
        link: _ctx.$utf8.encode(_ctx.data.title !== null ? _ctx.data.title : '')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "bg-accent2 row justify-center",
            style: _normalizeStyle(`margin-top: ${_ctx.$q.screen.gt.sm ? 48 : 0}px`)
          }, [
            _createVNode(_component_q_card, {
              class: "q-pa-lg bg-accent p-card column no-shadow",
              style: _normalizeStyle(`
                        border-radius: 18px;
                        position: relative;
                        width: 500px;
                        height: ${_ctx.$q.screen.gt.sm ? 400 : 500}px
                    `)
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$route.meta.page_ref_content.label), 1),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", _hoisted_4, [
                      _createTextVNode(_toDisplayString(_ctx.data.price) + " ", 1),
                      _createElementVNode("span", {
                        style: _normalizeStyle(`font-size: ${ (_ctx.data.priceSubText || '').length <= 7 ? 1.2 : (_ctx.data.priceSubText || '').length <= 12 ? 0.9 : 0.75}rem`),
                        class: "p-price-sub-text"
                      }, _toDisplayString(_ctx.data.priceSubText), 5)
                    ]),
                    _createElementVNode("div", {
                      class: "p-price-hint",
                      innerHTML: _ctx.data.hint
                    }, null, 8, _hoisted_5)
                  ]),
                  ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_p_cta, {
                          type: _ctx.type,
                          data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
                        }, null, 8, ["type", "data"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7))
                ]),
                _withDirectives(_createElementVNode("img", _hoisted_8, null, 512), [
                  [_vShow, _ctx.$q.screen.gt.sm]
                ])
              ]),
              _: 1
            }, 8, ["style"])
          ], 4)
        ]),
        _: 1
      }, 8, ["headline", "link"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_9, " abc "))
}