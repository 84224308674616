import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
    {
        path     : '/',
        redirect : { name: 'main_de-de' }
        // component : () => import('layouts/Root.vue')
    },

    // Always leave this as last one,
    // but you can also remove it
    {
        path      : '/:catchAll(.*)*',
        component : () => import('@/pages/Error404.vue')
    }
]

export default routes
