import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title", "onplay", "muted", "loop"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["default", "src", "label", "kind", "srclang"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click_on_init && _ctx.click_on_init(...args))),
      class: "row justify-center items-center",
      style: _normalizeStyle(`
                z-index: 4;
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.15);
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0
            `)
    }, [
      _createVNode(_Transition, {
        appear: "",
        "enter-active-class": "animated fadeIn",
        "leave-active-class": "animated fadeOut"
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_q_icon, {
            size: "100px",
            style: {"z-index":"5"},
            color: "primary",
            name: "volume_off"
          }, null, 512), [
            [_vShow, _ctx.init_muted_pulse]
          ])
        ]),
        _: 1
      })
    ], 4), [
      [_vShow, _ctx.init_muted]
    ]),
    ((_ctx.video.video.iv[0] || [{ url: '' }]).url)
      ? (_openBlock(), _createElementBlock("video", {
          key: 0,
          title: (_ctx.video.video.iv[0] || [{ alt: '' }]).alt || '',
          controls: "",
          autoplay: "",
          onplay: _ctx.onplay,
          muted: _ctx.muted,
          style: {"width":"100%"},
          loop: _ctx.muted,
          crossorigin: "anonymous"
        }, [
          _createElementVNode("source", {
            src: (_ctx.video.video.iv[0] || [{ url: '' }]).url,
            type: "video/mp4"
          }, null, 8, _hoisted_2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.video.vtts.iv || [], (vtt) => {
            return (_openBlock(), _createElementBlock("track", {
              key: vtt,
              default: _ctx.$route.meta.lang?.toLowerCase() === vtt.data.srclang.iv.toLowerCase(),
              src: (vtt.data.vffFile.iv || [{ url: '' }])[0].url,
              label: Object.values(vtt.data.label)[1],
              kind: vtt.data.type.iv,
              srclang: vtt.data.srclang.iv
            }, null, 8, _hoisted_3))
          }), 128))
        ], 8, _hoisted_1))
      : _createCommentVNode("", true)
  ]))
}