import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: "row justify-evenly wrap",
  style: {"width":"calc(100% + 32px)","margin-left":"-16px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_products_element = _resolveComponent("p-products-element")!

  return (['products'].includes(_ctx.type))
    ? (_openBlock(), _createBlock(_component_p_products_element, {
        key: 0,
        headline: _ctx.data.title,
        link: _ctx.$utf8.encode(_ctx.data.title !== null ? _ctx.data.title : '')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.pages || [], (element) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(element.__typename)), {
                key: element,
                class: "q-ma-md",
                data: element.data
              }, null, 8, ["data"]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["headline", "link"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "row justify-between wrap",
        style: _normalizeStyle(`
                ${
                    _ctx.$q.screen.gt.sm ?
                    'width: calc(100% + 32px); margin-left: -16px; display: grid; grid-gap: 32px; grid-template-columns: 1fr 1fr' :
                    'width: calc(100%); display: grid; grid-gap: 24px; grid-template-columns: 1fr'
                }
            `)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.pages || [], (element) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(element.__typename)), {
            key: element,
            data: element.data
          }, null, 8, ["data"]))
        }), 128))
      ], 4))
}