import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row justify-start no-wrap q-mb-md" }
const _hoisted_2 = { class: "row justify-start no-wrap q-mb-md text-body2" }
const _hoisted_3 = {
  class: "text-overline",
  style: {"line-height":"100%"}
}
const _hoisted_4 = {
  class: "text-caption",
  style: {"line-height":"100%"}
}
const _hoisted_5 = {
  class: "row justify-between no-wrap items-center",
  style: {"width":"100%"}
}
const _hoisted_6 = {
  class: "text-caption",
  style: {"line-height":"100%"}
}
const _hoisted_7 = {
  class: "text-overline q-mt-sm q-mb-md",
  style: {"line-height":"100%"}
}
const _hoisted_8 = { class: "row justify-start no-wrap text-body2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_rating = _resolveComponent("q-rating")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createBlock(_component_q_card, {
    flat: "",
    class: "bg-accent",
    style: `overflow: hidden; border-radius: 18px`
  }, {
    default: _withCtx(() => [
      (_ctx.$q.screen.gt.sm)
        ? (_openBlock(), _createBlock(_component_q_card_section, {
            key: 0,
            class: "row justify-start",
            style: {"overflow":"hidden"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_q_rating, {
                  "model-value": Object.values(_ctx.data.rating || {})[1],
                  size: "1.5em",
                  icon: "star_border",
                  "icon-selected": "star"
                }, null, 8, ["model-value"])
              ]),
              _createElementVNode("div", _hoisted_2, _toDisplayString(Object.values(_ctx.data.message || {})[1]), 1),
              _createElementVNode("div", _hoisted_3, [
                _createTextVNode(_toDisplayString(Object.values(_ctx.data.name || {})[1]) + " - ", 1),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$m(Object.values(_ctx.data.date || {})[1]).format('DD. MMMM YYYY')), 1)
              ])
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_q_card_section, {
            key: 1,
            class: "row justify-start",
            style: {"overflow":"hidden"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_q_rating, {
                  "model-value": Object.values(_ctx.data.rating || {})[1],
                  size: "1.5em",
                  icon: "star_border",
                  "icon-selected": "star"
                }, null, 8, ["model-value"]),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$m(Object.values(_ctx.data.date || {})[1]).format('DD. MMMM YYYY')), 1)
              ]),
              _createElementVNode("div", _hoisted_7, _toDisplayString(Object.values(_ctx.data.name || {})[1]), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(Object.values(_ctx.data.message || {})[1]), 1)
            ]),
            _: 1
          }))
    ]),
    _: 1
  })), [
    [_directive_ripple, { color: 'primary' }]
  ])
}