import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row justify-center q-mt-md",
  style: {"width":"100%"}
}
const _hoisted_2 = {
  key: 1,
  class: "row justify-center wrap q-gutter-lg full-width"
}
const _hoisted_3 = {
  key: 0,
  class: "row justify-center q-mt-md",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_cta = _resolveComponent("p-cta")!
  const _component_p_products_element = _resolveComponent("p-products-element")!

  return (['products'].includes(_ctx.type))
    ? (_openBlock(), _createBlock(_component_p_products_element, {
        key: 0,
        headline: _ctx.data.title,
        link: _ctx.$utf8.encode(_ctx.data.title !== null ? _ctx.data.title : '')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["justify-evenly wrap", {
                    row: _ctx.$q.screen.gt.sm,
                    column: _ctx.$q.screen.lt.md
                }]),
            style: {"width":"calc(100% + 32px)","margin-left":"-16px"}
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.content || [], (element) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(element.__typename)), {
                key: element,
                class: "q-ma-md",
                style: _normalizeStyle(`width: calc(${_ctx.$q.screen.gt.sm ? '33%' : '100%'} - 32px)`),
                data: element
              }, null, 8, ["style", "data"]))
            }), 128)),
            ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_p_cta, {
                    type: _ctx.type,
                    data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
                  }, null, 8, ["type", "data"])
                ]))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _: 1
      }, 8, ["headline", "link"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.content || [], (element) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(element.__typename)), {
            key: element,
            data: element
          }, null, 8, ["data"]))
        }), 128)),
        ((_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data.__typename !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_p_cta, {
                type: _ctx.type,
                data: (_ctx.data.cta || [{ data:{ __typename: '' } }])[0].data
              }, null, 8, ["type", "data"])
            ]))
          : _createCommentVNode("", true)
      ]))
}