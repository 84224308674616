import { API_Query_Data_Helper } from '../API'
import { CMS_COMPONENT_Videos } from './videos'

export class CMS_COMPONENT_Videopoleelementlink {
    id: string
    label: { [key: string]: string }
    description: { [key: string]: string }
    link: { [key: string]: string }
    __typename: string

    constructor () {
        this.id = ''
        this.label = { de: '' }
        this.description = { de: '' }
        this.link = { de: '' }
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Videopoleelementvideo {
    id: string
    label: { [key: string]: string }
    video: { iv: CMS_COMPONENT_Videos[] }
    description: { [key: string]: string }
    options: { iv: CMS_COMPONENT_Videopoleelement_Content[] }
    __typename: string

    constructor () {
        this.id = ''
        this.label = { de: '' }
        this.video = { iv: [] }
        this.description = { de: '' }
        this.options = { iv: [] }
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Videopoleelementvideo2 {
    id: string
    label: { [key: string]: string }
    video: { iv: API_Query_Data_Helper<CMS_COMPONENT_Videos>[] }
    description: { [key: string]: string }
    options: { iv: CMS_COMPONENT_Videopoleelement_Content[] }
    __typename: string

    constructor () {
        this.id = ''
        this.label = { de: '' }
        this.video = { iv: [] }
        this.description = { de: '' }
        this.options = { iv: [] }
        this.__typename = ''
    }
}

export class CMS_COMPONENT_Videopoleelement {
    id: string
    label: string
    video?: CMS_COMPONENT_Videos
    link?: string
    description: string
    options?: CMS_COMPONENT_Videopoleelement_Content[]
    __typename: string

    constructor () {
        this.id = ''
        this.label = ''
        this.description = ''
        this.link = ''
        this.options = []
        this.__typename = ''
    }
}

export type CMS_COMPONENT_Videopoleelement_Content =
    CMS_COMPONENT_Videopoleelementvideo2 |
    CMS_COMPONENT_Videopoleelementvideo |
    CMS_COMPONENT_Videopoleelementlink
