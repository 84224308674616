
import { CMS_SCHEMA_Reviews } from '@/types/cms/reviews'
import { defineComponent } from 'vue'

// components

export default defineComponent({
    name: 'p_components_main_reviews_review',

    props: {
        data: {
            default: new CMS_SCHEMA_Reviews()
        }
    },

    data () {
        return {
            ratingModel: 4
        }
    }
})
