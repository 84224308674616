
import { defineComponent } from 'vue'
import { CMS_COMPONENT_Homepagecustomers } from '@/types/cms/homepage'

// components
import Basic_Element from '@/components/layout/Basic_Element.vue'

export default defineComponent({
    name: 'p_components_main_customers',

    components: {
        'p-basic-element': Basic_Element
    },

    props: {
        data: {
            default: new CMS_COMPONENT_Homepagecustomers()
        }
    }
})
