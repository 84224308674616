
import { defineComponent } from 'vue'
import { CMS_COMPONENT_Pageslinks } from '@/types/cms/pages'

// components
import Basic_Products_Element from '@/components/layout/Basic_Products_Element.vue'


export default defineComponent({
    name: 'p_components_pages_pages',

    components: {
        'p-products-element': Basic_Products_Element
    },

    props: {
        data: {
            default: new CMS_COMPONENT_Pageslinks()
        },
        type: {
            default: ''
        }
    }
})
