import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-181f7b2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt", "title", "src"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    class: "q-pa-lg bg-accent p-card row justify-center no-shadow",
    style: `overflow: hidden; border-radius: 18px`
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        loading: "lazy",
        class: "q-mb-md",
        style: {"width":"50%"},
        width: "150",
        height: "150",
        alt: (_ctx.data.icon || [{ alt: '' }])[0].alt || '',
        title: (_ctx.data.icon || [{ alt: '' }])[0].alt || '',
        src: `
                ${(_ctx.data.icon || [{ url: '' }])[0].url || ''}${
                    (_ctx.data.icon || [{ imageQuality: 0 }])[0].imageQuality ?
                    '?quality=' + (_ctx.data.icon || [{ imageQuality: 0 }])[0].imageQuality :
                    ''
                }
            `
      }, null, 8, _hoisted_1),
      _createElementVNode("div", {
        innerHTML: _ctx.data.content
      }, null, 8, _hoisted_2)
    ]),
    _: 1
  }))
}