import { STATE_Helper } from '@/types/states/helper'
import { MutationTree } from 'vuex'

const mutation: MutationTree<STATE_Helper> = {
    M_SET_DARK (state, active: boolean) {
        state.dark.is_dark = active
    }
}

export default mutation
