
import { CMS_SCHEMA_Editor } from '@/types/cms/editor'
import { defineComponent } from 'vue'

// components

export default defineComponent({
    name: 'p_components_pages_editor_editor',

    props: {
        data: {
            default: new CMS_SCHEMA_Editor()
        }
    }
})
