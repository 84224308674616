
export class CMS_SCHEMA_Calltoactions {
    name?: { [key: string]: string }
    label?: { [key: string]: string }
    description?: { [key: string]: string }
    link?: { [key: string]: string }
    newPage?: { [key: string]: boolean }
    __typename: string

    constructor () {
        this.name = { iv: '' }
        this.label = { de: '' }
        this.description = { de: '' }
        this.link = { de: '' }
        this.newPage = { de: false }
        this.__typename = ''
    }
}
