import { boot } from 'quasar/wrappers'

import Products from '@/components/main/products/Index.vue'
import Product from '@/components/main/products/Product.vue'
import Hero from '@/components/main/hero/Index.vue'
import Customers from '@/components/main/customers/Index.vue'
import Customer from '@/components/main/customers/Customer.vue'
import Reviews from '@/components/main/reviews/Index.vue'
import Review from '@/components/main/reviews/Review.vue'
import Team from '@/components/main/team/Index.vue'
import Person from '@/components/main/team/Person.vue'
import empty from '@/components/Empty.vue'

//pages
import pages_hero from '@/components/pages/hero/Index.vue'
import pages_text from '@/components/pages/Text.vue'
import pages_histgramh from '@/components/pages/histrogramh/Index.vue'
import pages_histgramh_steps from '@/components/pages/histrogramh/Step.vue'
import pages_grid from '@/components/pages/grid/Index.vue'
import pages_grid_element_text from '@/components/pages/grid/Text_Element.vue'
import pages_grid_element_text_icon from '@/components/pages/grid/Text_Icon_Element.vue'
import pages_video from '@/components/pages/video/Index.vue'
import pages_image from '@/components/pages/Image.vue'
import pages_grid_list from '@/components/pages/grid_list/Index.vue'
import pages_grid_list_element from '@/components/pages/grid_list/Element.vue'
import pages_text_image from '@/components/pages/Text_Image.vue'
import pages_payments from '@/components/pages/payments/Index.vue'
import pages_links from '@/components/pages/pages/Index.vue'
import pages_pages from '@/components/pages/pages/Page.vue'
import pages_editor_ad from '@/components/pages/editor/Index.vue'
import pages_editor_ad_editor from '@/components/pages/editor/Editor.vue'
import pages_price from '@/components/pages/Price.vue'
import pages_video_pole from '@/components/pages/video_pole/Index.vue'

const component_register = (name: string) => {
    switch(name){
    case 'HomepageproductsComponent':
        return Products
    case 'ProductComponent':
    case 'p-product':
    case 'Products':
        return Product
    case 'HomepageheroComponent':
        return Hero
    case 'HomepagecustomersComponent':
        return Customers
    case 'Customers':
        return Customer
    case 'HomepagereviewsComponent':
        return Reviews
    case 'Reviews':
        return Review
    case 'HomepageteamComponent':
        return Team
    case 'Persons':
        return Person
    case 'PagesheroComponent':
        return pages_hero
    case 'PagestextComponent':
        return pages_text
    case 'PageshistogramhComponent':
        return pages_histgramh
    case 'Histogramsteps':
        return pages_histgramh_steps
    case 'Histogramsteps':
        return pages_histgramh_steps
    case 'PagesgridComponent':
        return pages_grid
    case 'PagesgridelementtextComponent':
        return pages_grid_element_text
    case 'PagesgridelementtexticonComponent':
        return pages_grid_element_text_icon
    case 'PagesvideoComponent':
        return pages_video
    case 'PagesimageComponent':
        return pages_image
    case 'PagesgridlistComponent':
        return pages_grid_list
    case 'Gridlistelements':
        return pages_grid_list_element
    case 'PagestextimageComponent':
        return pages_text_image
    case 'PagespaymentsComponent':
        return pages_payments
    case 'PageslinksComponent':
        return pages_links
    case 'Pages':
        return pages_pages
    case 'PageseditoradComponent':
        return pages_editor_ad
    case 'Editors':
        return pages_editor_ad_editor
    case 'PagespriceComponent':
        return pages_price
    case 'PagesvideopoleComponent':
        return pages_video_pole
    default:
        return empty
    }
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $component_register: any
    }
}

export default boot(({ app }) => {
    app.config.globalProperties.$component_register = component_register
})

export { component_register }
