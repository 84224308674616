
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { defineComponent, ref } from 'vue'

// components
import Basic_Element from '@/components/layout/Basic_Element.vue'
import { CMS_COMPONENT_Homepagereviews } from '@/types/cms/homepage'

const __default__ = defineComponent({
    name: 'p_components_main_reviews',

    setup () {
        const scrollInfo = ref({
            position: {
                top  : 0,
                left : 0
            },
            direction: 'down'
        })

        return {
            scrollInfo,
            onScroll (info: { position: { top: number, left: number }, direction: 'up' | 'down' }) {
                scrollInfo.value = info
            }
        }
    },

    data () {
        return {
            position           : 0,
            show_review_forest : false
        }
    },

    watch: {
        'scrollInfo.position.top': {
            handler (new_value: number) {
                // const target = document.getElementsByClassName('reviews_basic_element')
                const target = this.$refs.reviews_basic_element

                if (target && target !== null) {
                    if (this.scrollInfo.direction === 'down' && !this.show_review_forest) {
                        // @ts-ignore
                        const r_target: HTMLElement = target.$el
                        const client_height = r_target.offsetHeight
                        const screen_height = window.screen.height
                        const actual = new_value + (screen_height / 2)
                        const start = r_target.offsetTop - 200
                        const end = client_height + start - 200

                        if (actual < start) {
                            this.position = 0.2
                            this.show_review_forest = false
                        } else if (actual > end) {
                            this.position = 1
                            setTimeout(() => {
                                this.show_review_forest = true
                            }, 500)
                        } else {
                            const relative = actual - start
                            const area = end - start
                            const result = relative / area
                            this.position = result > 0.2 ? result : 0.2

                            if (this.position > 0.85) {
                                setTimeout(() => {
                                    this.show_review_forest = true
                                }, 50)
                            }
                        }
                    }
                } else if (this.scrollInfo.direction === 'down') {
                    this.position = 0.2
                    this.show_review_forest = false
                }
            },
            immediate: true
        }
    },

    components: {
        'p-basic-element': Basic_Element
    },

    props: {
        data: {
            default : new CMS_COMPONENT_Homepagereviews(),
            type    : CMS_COMPONENT_Homepagereviews
        }
    },

    mounted () {
        (function (w, d, s, o, f, js, fjs) {
            // @ts-ignore
            w['Targetbox-Trees-Widget'] = o
            // @ts-ignore
            w[o] =
            // @ts-ignore
                w[o] ||
                function () {
                    // @ts-ignore
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    (w[o].q = w[o].q || []).push(arguments)
                };
            // @ts-ignore
            (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0])
            // @ts-ignore
            js.id = o
            // @ts-ignore
            js.src = f
            // @ts-ignore
            js.async = 1
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            fjs.parentNode.insertBefore(js, fjs)
        })(
            window,
            document,
            'script',
            'mw',
            'https://widget-v1.reviewforest.org/scripts.js'
        )
        // @ts-ignore
        mw('amount', {
            id       : '60ae3548bf91f02ce19d619b',
            location : '60ae3548a0da0dd1065ba9a5',
            url      : 'https://reviewforest.org/pagisto',
            language : 'de',
            name     : 'Pagisto GmbH',
            types    : "['badge']"
        })
    }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "db8c85c0": (_ctx.position)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__