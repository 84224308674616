
import { CMS_SCHEMA_Grid_Element_Text } from '@/types/cms/grid_elements'
import { defineComponent } from 'vue'

// components

export default defineComponent({
    name: 'p_components_pages_grid_text_element',

    props: {
        data: {
            default: new CMS_SCHEMA_Grid_Element_Text()
        }
    }
})
